import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getChannelMemberWithdrawList, auditChannelMemberWithdraw } from '@/api/channel_member';
export default {
  name: "channelWithdraw",
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        from: 0,
        user_id: null,
        app_id: null,
        status: null,
        page: 1,
        page_size: 20
      },
      total: 0,
      appOptions: [{
        label: "小账本",
        value: 9
      }, {
        label: "弹个券",
        value: 8
      }, {
        label: "CPS云",
        value: 12
      }],
      statusOptions: [{
        label: "待处理",
        value: 1
      }, {
        label: "通过",
        value: 2
      }, {
        label: "拒绝",
        value: 3
      }]
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      getChannelMemberWithdrawList(_objectSpread({}, this.queryParams)).then(function (res) {
        if (res.code === 0) {
          _this.loading = false;
          _this.data = res.data.data;
          _this.total = res.data.total_count;
        }
      });
    },
    handleAudit: function handleAudit(row, audit) {
      var _this2 = this;

      this.$confirm('您确定要处理这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        auditChannelMemberWithdraw({
          id: row.id,
          app_id: row.app_id,
          audit: audit
        }).then(function (res) {
          if (res.code === 0) {
            _this2.$message.success('处理成功');

            _this2.requestData();
          }
        });
      }).catch(function () {
        _this2.$message.info('已取消处理');
      });
    }
  }
};